import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequestAPI } from "../../auth/authConfig";

function Login(props) {
    const { instance, accounts, inProgress  } = useMsal();
    const { setToken, setPermissions, setError } = props;
    const tenantId = process.env.REACT_APP_TENANT_ID;
    const hasADFSToken = document.cookie.includes('adfs_token');

    async function setUserPermissions(token){
        if(token){

            const fetchHeaders = {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token
                })
            };

            const permissionsResponse = await fetch('/UserInfo/GetIsLincesedUser', fetchHeaders);
            if(permissionsResponse.status == 401){
                sessionStorage.clear();
                document.cookie = 'adfs_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.location.href = '/';
            }
            else{
                const permissionsData = await permissionsResponse.json();
                if(permissionsResponse.ok === true){
                    if(permissionsData && permissionsData.isLicensedUser === true){
                        sessionStorage.setItem('permissions', 'corporate');
                        setPermissions('corporate');
                    } else {
                        sessionStorage.setItem('permissions', 'hotel');
                        setPermissions('hotel');
                    }
                } else {
                    console.log('Error getting permissions');
                    setError(true);
                }
            }
        } else {
            console.log('No token provided');
            setError(true);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if(hasADFSToken){
                await setUserPermissions(document.cookie.split('; ').find(row => row.startsWith('adfs_token')).split('=')[1]);
                const ssoRequest = {
                    domainHint: tenantId,
                    scopes: loginRequestAPI.scopes,
                    account: accounts.length > 0 ? accounts[0] : {}
                };
        
                const loginRequest = {
                    ...loginRequestAPI,
                }
        
                if (inProgress === InteractionStatus.None && accounts.length > 0 && sessionStorage.getItem('permissions') === 'corporate') {
                    instance.acquireTokenSilent(ssoRequest).then((response) => {
                        sessionStorage.setItem('accessTokenAPI', response.accessToken);
                        setToken(response.accessToken);
                        setUserPermissions(response.accessToken);
                    }).catch(async (e) => {
                        if (e.name === 'InteractionRequiredAuthError') {
                            await instance.acquireTokenRedirect(ssoRequest);
                        }
                        throw e;
                    });
                } else if (inProgress === InteractionStatus.None && accounts.length === 0 && sessionStorage.getItem('permissions') === 'corporate') {
                    instance.loginRedirect(loginRequest);
                }
            }

        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress, accounts, instance, hasADFSToken]);

}; export default Login;