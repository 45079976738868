import { adfsConfig } from '../auth/authConfig';
import AdfsTokenService from './AdfsTokenService';
import LoggerService from './LoggerService';

export default function AdfsLoginService() {

    const logger = new LoggerService('AdfsLoginService');

    const that = {
        create: () => {
            const adfsIsEnabled = adfsConfig.authN.parameters.enabled === 'true';

            if(!adfsIsEnabled) {
                logger.info('ADFS is not enabled');
                return;
            }

            // Redirect to ADFS login page for letting the user to login
            const adfsUrl = adfsConfig.authN.url;
            const adfsQuery = Object.keys(adfsConfig.authN.parameters)
                .map((key) => `${key}=${adfsConfig.authN.parameters[key]}`)
                .join('&');

            window.location.replace(`${adfsUrl}?${adfsQuery}`);
        },

        get: () => {
            const adfsIsEnabled = adfsConfig.authN.parameters.enabled === 'true';

            if(!adfsIsEnabled) {
                logger.info('ADFS is not enabled');
                return 'Not enabled';
            }

            // Check if there is a ADFS JWT token stored in session
            const adfsTokenService = new AdfsTokenService();
            const adfsJwtToken = adfsTokenService.get();

            return adfsJwtToken;
        },
    };

    return that;
}