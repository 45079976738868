import React from 'react';
import ScrollToTop from './components/hooks/scrollToTop';
import HomePage from './components/layout/homePage';
import DnsCheck from "./dnsCheck/dnsCheckPage";

function App(){
  const location = window.location.href;

  return (
    <>
      <ScrollToTop />
      {location.includes('/dnscheck') === true ?
        <DnsCheck />
        :
        <HomePage />
      }
    </>
  );
} export default App;
